<template>
	<div :style="bg" >
		<Header :title="$t('mall.cartList')"></Header>
		<div class="column-center-content main-content" style="margin-top: 45px">
			<!-- <div v-for="item in historyList" :key="item.id"> -->
			
				<div style="width: 100%; padding: 16px; background-color: #FFFFFF; margin-top: 1px;">
					<div class="row-content">
						<img src="https://i.loli.net/2021/09/16/LNlv9jhdP6Hs2Aw.jpg" style="width: 60px; height: 60px; "/>
						<div style="margin-left: 16px; width: 100%; display: flex; flex-direction: row; align-items: center;">
							<label style="font-size: 18px; width: 60%;">富氢水机</label>
							<div class="row-content" style="justify-content: flex-end; margin-top: 5px; width: 40%;">
								<button>
									<i class="fa fa-plus-circle" style="color: #FF0000; font-size: 22px;" aria-hidden="true"></i>
								</button>
								<label style="margin: 0px 20px;">1</label>
								<button>
									<i class="fa fa-minus-circle" style="color: #FF0000; font-size: 22px;" aria-hidden="true"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
				
				<div style="width: 100%; padding: 16px; background-color: #FFFFFF; margin-top: 1px;">
					<div class="row-content">
						<img src="https://i.loli.net/2021/09/12/6oO54FjSbEBc9tG.png" style="width: 60px; height: 60px; "/>
						<div style="margin-left: 16px; width: 100%; display: flex; flex-direction: row; align-items: center;">
							<label style="font-size: 18px; width: 60%;">益生菌（1）</label>
							<div class="row-content" style="justify-content: flex-end; margin-top: 5px; width: 40%;">
								<button>
									<i class="fa fa-plus-circle" style="color: #FF0000; font-size: 24px;" aria-hidden="true"></i>
								</button>
								<label style="margin: 0px 20px;">2</label>
								<button>
									<i class="fa fa-minus-circle" style="color: #FF0000; font-size: 24px;" aria-hidden="true"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			<!-- </div> -->
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../components/NavHeader.vue'
	
	export default {
		name: 'CartList',
		components: {
			Header
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				}
			}
		},
		methods: {
			
		}
	}
</script>

<style>
</style>
